
import Modal from '@/components/Shared/Modal/index.vue'
import AdminContentEditable from '@/mixins/AdminContentEditable'
import WebSiteVideo from './WebSiteVideo.vue'
export default {
  mixins: [AdminContentEditable],
  components: { Modal, WebSiteVideo },
  data() {
    return {
      showVideo: false,
      st: this.$store.state.settings.contents[this.$i18n.locale],
      componentList:
        this.$store.state.settings.components !== null
          ? this.$store.state.settings.components.split(',')
          : [],
    }
  },
  computed: {
    tags() {
      return this.$store.state.settings.tags.split(',').slice(0, 3)
    },
  },
  methods: {
    UPDATE_BANNER() {
      location.reload()
    },
    SET_SHOW_VIDEO() {
      if (this.$store.state.settings.videoPath !== '' && this.componentList.includes('WebSiteVideo')) {
        this.showVideo = true
        setTimeout(() => {
          document.getElementById('video').scrollIntoView()
        }, 150)
      }
    },
  },
}
